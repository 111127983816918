

/*---------------------------------general css here-------------------------------*/

@keyframes scrollbarLoad {
    from {
        background: #f1f1f1;
    }
    to {
        background: #ff0000;
    }
}

html, body {
    max-width: 100vw;
    overflow-x: hidden;
    scroll-behavior: smooth;
    overflow-y: scroll;
  }



::-webkit-scrollbar {
    width: 10px;
    animation-name: scrollbarLoad;
    animation-duration: 1s;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #ffffff;   
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(187, 106, 106);
    position: absolute;
    display:block;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(0, 0, 0);
    margin-right: 2px;
  }


.Container{
    margin-top:143 px;
}

#content{
    margin-top: 140px;
    margin-left: 60px;

}

.fab, .far{
    margin: 0;
    color: rgb(0, 0, 0);
}

body {
    background: '#F8F8F8';
    margin: 0;
    font-family: lato, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth;
}
  
h1{
    font-family: lato;
    font-weight: 300;
    font-style: normal;
    font-size: 3.5vw;
    color: black;
}

h2{
    font-family: lato;
    font-weight: 600;
    font-size: 2em;
    font-style: italic;
    font-size: 2vw;
    text-align: left !important;
}

h3{
    font-weight: 400;
    font-style: italic;
    color: black;
    line-height:.7em;
    font-size: 1.7vw;
}
h4{
    font-weight: 500;
    font-size: 1.2vw;
}

p, h2, h1{
    color: rgb(0, 0, 0);
    transition: .2s;
}

p{
    font-size: 1vw;
    font-weight: 300;
}
@media screen and (min-width: 1600px){
    p{
        font-size: 20px !important;
    }
    h1{
        font-size: 4em;
    }
    h3{
        font-size: 1.5em;
    }
    .midDelete{
        display: inline-block !important;
    }
    .bigDelete{
        display: none !important;
    }
}
@media screen and (max-width: 500px){
    p{
        font-size: 14px!important;
    }
    h1{
        font-size: 8.5vw!important;
        line-height: 1.1em!important;
        margin-bottom: 1px!important;
    }
    h2{
        font-size: 18px!important;

    }
    h3{
        font-size: .7em;
    }
    h4{
        font-weight: 500;
        font-size: .8em;
    }
    .mobileDelete{
        display: none !important;
    }
    .mobileFull{
        width: 100% !important;
        display: block !important;
        position: relative !important;
        text-align: left !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    .mobileFullPad{
        width: 76% !important;
        display: block !important;
        position: relative !important;
        padding: 12% !important;
        margin: 0 !important;
        text-align: left !important;
    }
    .mobileFullPadBot{
        width: 76% !important;
        display: block !important;
        position: relative !important;
        padding: 12% !important;
        padding-bottom: 0% !important;
        margin: 0 !important;
        text-align: left !important;
    }
    .mobileAuto{
        display: block !important;
        position: relative !important;
        height: auto !important;
    }

    .mobileAuto img{
        margin-left: -20px;
    }

    .regDelete{
        display: block !important;
    }
    .mobileCrop{
       overflow-x: hidden;
       height: 100vh !important;
    }
    div{
        background-size: 100% !important;
    }

    .promo-img p{
        font-size: 8px !important;
        line-height: 9px;
    }
}

.regDelete{
    display: none ;
}
.midDelete{
    display: none ;
}



/* prevent unnecessary side scroll*/
.App{
    overflow: hidden;
    max-width: 100vw;
}
/*-------------------------------------- NavBar ------------------------------------------------*/

#nav{
    transition: .3s;
    padding-top: 30vh;
}


#head-left:hover{
    width: 200px !important;
}

#head-left:hover i{
    left: 180;
}

#head-left:hover a{
    margin-left: 20px;
}

nav a{
    font-family: lato;
    font-weight: 300;
    font-style: normal;
    font-size:30px;
    margin-left: -200px;
    color: rgb(0, 0, 0);
    text-decoration: none;
    transition: .2s;
    display: list-item;
    list-style-type: none;

  }

@media screen and (max-width: 500px){
    #head-left:hover ~ #x i{
        padding-left: 210px;
        width: 50px;
        height: 50px;
        font-size: 30px;
    }
      
}

  
nav a:hover{
    font-family: lato;
    font-weight: 400;
    font-style: normal;
    margin-left: 0px;
    font-size:31px;
    padding: 10px;
    color: rgb(110, 33, 33);
    text-decoration: none;
}


#nav-under{
    transition: .3s;
    display: none;
}

#head-left:hover .hamburger{
    margin-left: -140px !important;
}

#head-left:hover .nameMine{
    padding-top: 0vh !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
}

/*-------------------------------------- Gallery ------------------------------------------------*/

@keyframes slideInFromBottom {
    0% {
      opacity: 0;
      transform: translateY(50%);
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @media screen and (max-width: 500px){
    .gallery-collumn{
        width: 80vw !important;
    }
}
.light-box{
    top:0;
    left:0;
    display:none;
    position: fixed !important;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.63);
    z-index:2 !important;
    align-items: center;
    justify-content: center;
    transform: none;
}

.light-image{
    height: 35vw;
    display: flexbox;
    margin: auto;
}

#one:target,  #two:target,  #three:target,  #four:target,  #five:target,  #six:target,  #seven:target,  #eight:target,  #nine:target{
    display: flex;
    top:0 !important;
    left:0 !important;
}

.gallery-image{
    max-width: 100%;
    transition: 0.3s;
    -moz-user-select: none;
    user-select: none;
}

.gallery-item{
    /*animation: .3s ease-out 0s 1 slideInFromBottom;*/
    transition: 0.3s;
    /*border: 1px solid black;*/
    position: relative;
    overflow: hidden;
    user-select: none;
}

.gallery-title{
    top: -170px;
    width: 100%; 
    height: auto;
    transition: 0.3s;
    background-color: #F0CF77;
    color: #fff;
    position: absolute;
    z-index: 1;
}

.gallery-item:hover .gallery-title{
    top: -30px;
}

.gallery-item:hover .gallery-image{
    transform: scale(1.09) !important;
}

/*-------------------------------------- Projects ------------------------------------------------*/
@media screen and (max-width: 500px){
    .project-wrapper{
        width: 80vw !important;
        height: 80vw !important;
        display: inline-block;
        overflow: hidden;
        margin-left: 0vw !important;
        transition: .3s;
    }
    .project-desc-back{
        width: 80vw !important;
        height: 80vw !important;
        transition: .3s;
    }
    #projects-wrapper{
        padding-top: 4vw !important;
        transition: .3s;
        margin: auto !important;
    }

    #cover{
        height: 180vh !important;
    }

    .project-header-img{
        height: 100vh !important;
        width: auto !important;
    }
    .project-wrapper p{
        font-size: 15px !important;
    }
}
@media screen and (min-width: 1600px){
    .project-wrapper{
        width: 26vw !important;
        height: 26vw !important;
        display: inline-block;
        overflow: hidden;
        transition: .3s;
    }
    .project-desc-back{
        width: 26vw !important;
        height: 26vw !important;
        transition: .3s;
    }
    #projects-wrapper{
        transition: .3s;
        margin-left: 19vw !important;
    }

    #cover{
        height: 180vh !important;
    }
    .project-wrapper:hover #cs .project-desc{
        top: 26vw !important;
    }

    .project-wrapper:hover #cg .project-desc{
        top: 26vw !important;
    }

    .project-wrapper:hover .project-desc{
        top: 26vw !important;
    }

    #projects-title h3{
        font-size: 20px !important;
        padding-bottom: 8px !important;
    }
    .project-wrapper h2{
        margin-top: 5px !important;
        font-size: 1.2vw !important;
    }
    .project-wrapper p{
        display: none !important;
    }
    .project-wrapper h1{
        font-size: 2.8vw !important;
        line-height: 100% !important;
        font-weight: 300 !important;
    }

}
@media screen and (max-width: 1200px) and (min-width: 500px){
    .project-wrapper p{
        margin-top: 10px;
        padding-right: 20px;
        font-size: 1.5vw !important;
        display: none;
    }

}
.project-wrapper{
    width: 32vw;
    height: 32vw;
    margin: 0vw;
    margin-top: -5px;
    display: inline-block;
    overflow: hidden;
    transition: .3s;
}

.project{
    position: relative;
    height: 100%;
    transition: .3s;
}

.project-wrapper h1, .project-wrapper h2, .project-wrapper p{
    text-align: left;
    z-index: 1;
}

.project-wrapper:hover h1, .project-wrapper:hover h2, .project-wrapper:hover p{
    color: white !important;
}


.project-wrapper h1{
    font-size: 3.2vw;
    margin: 0px;
    font-weight: 300;
    line-height: 3.2vw;
}

.project-wrapper h2{
    margin-top: 0px;
    font-size: 1.6vw;
}

.project-wrapper:hover h2{
    margin-top: 0px;
    font-size: 1.5vw;
}

.project-wrapper p{
    margin-top: 10px;
    padding-right: 20px;
    font-size: 18px;
    display: none;
}

.project:hover .fas{
    top:0px !important;
}

/*--------------------------------sort buttons--------------------------------------*/
#projects-title h3{
    font-weight: 300 !important;
    font-style: normal;
    text-transform: uppercase;
    line-height: 50%;
    font-size: 14px;
    border: none;
    transition: .3s;
    padding-bottom: 8px;
}

#projects-title a{
    transition: .3s;
    border: none;
    text-decoration: none !important;
    user-select: none;
}
#projects-title a:hover{
    transition: .3s;
    border-color:  rgb(187, 106, 106);
}
#projects-title h3:hover{
    color:  rgb(187, 106, 106);
}
/*--------------------------------sort buttons--------------------------------------*/


.project-image{
    width: 100%;
    position: absolute;
}

.project-desc{
    position: absolute;
    padding: 20px;
    top: 0vw;
    transition:ease-out .3s ;
    width: 90%;
}
#cs .project-desc{
    top: 0vw;
}

.project-wrapper:hover .project-desc{
    top: 24vw;
}

.project-wrapper:hover #cs .project-desc{
    top: 20.6vw;
}

.project-wrapper:hover .project-sub{
    display: none !important;
}

.project-header-img{
    width: 28vw;
    height: auto;
    transition: .6s;
}

.project-header-logo{
    width: 28vw;
    height: auto;
    transition: .6s;
}

.project-header-desc:hover{
    width: 80vw;
    background-color: #fff;
    position: relative;
    height: auto;
    transition: .6s;
    display: none;
    padding: 10vw;
}


.project-header-desc-full{
    width: 80vw;
    background-color: #fff;
    position: relative;
    height: auto;
    transition: .6s;
    display: none;
    padding: 10vw;
}

.promo-img{
    width: 20vw;
    height: auto;
    display: block;
    margin: 1vw;
    display: inline-block;
    position: relative;
    z-Index: '66';
}

.promo-desc{
    margin: 1vw;
    margin-top: 2vw;
    overflow: hidden;
}


.project-desc-back{
    position: absolute;
    height: 32vw;
    width: 32vw;
    top: 0vw;
    background: rgba(255, 255, 255, 0);
    transition: ease-out .4s ;
}

.project-wrapper:hover .project-desc-back{
    top: 32vw;
}

#promo-img-1:hover + #promo-desc-1{
    transition: .3s;
    margin-left: 24vw !important;
}

#promo-img-2:hover + #promo-desc-2{
    transition: .3s;
    margin-top: 2vw !important;
}

#promo-img-3:hover + #promo-desc-3{
    transition: .3s;
    margin-top: -43vw !important;
}

#promo-img-5:hover + #promo-desc-5{
    transition: .3s;
    margin-left: 2vw !important;
} 

#promo-img-6:hover + #promo-desc-6{
    transition: .3s;
    margin-top: -43vw !important;
}

/*--------------------devon--------------------*/

.devonContent{
    display: none;
    top:0;
    position: absolute;
}

#devon-gilfillian:target .devonContent{
    display: block;
    top:0;
}

.project-wrapper #devon h1{
    color:#5A2727
}

.project-wrapper #devon h2{
    color:#EA9B70;
}

.project-wrapper #devon p{
    color: rgba(0, 0, 0, 0.384);
    font-weight: 300;
    display: block;
}

#devon.project .project-sub{
    display: block;
    color:#EA9B70 !important;
}

#devon-gilfillian{
    width: 28vw;
    height: 28vw;
    background: black;
    opacity:0;
    transition: .5s;
    position: absolute;
    top: 0px;
    left: 6vw;
    z-index: 1;
    pointer-events: none;
}

#devon-gilfillian:target{
    width: 98vw;
    height: 100vw;
    opacity: 1;
    background: #FFF2EB;
    top:0;
    position: absolute;
    left: 0;
    pointer-events: all;
    z-index: 100;
}

#devon-gilfillian:target .project-header-img {
    width: 100%;
    height: auto;
    background-size:cover;
    position: fixed;
    z-index: -1;
}

#devon-gilfillian:target .project-header-logo {
    width: 75vw;
    height: auto;
    top: 7vw;
    margin-left: 12vw;
    margin-right: 12vw;
    background-size:cover;
    position: relative;
}

#devon-gilfillian:target .project-header-desc-full{
    margin-top:10vw;
    display: block;
    background-color: white;
}


#devon.project .project-desc-back{
    background: rgba(255, 242, 235, 0.959) !important;
}

/*--------------------judah--------------------*/
#judah-and-the-lion{
    width: 28vw;
    height: 28vw;
    background: black;
    opacity:0;
    transition: .5s;
    position: absolute;
    top: 0px;
    left: 6vw;
    z-index: 1;
    pointer-events: none;
}

#judah-and-the-lion:target{
    z-index: 100;
}

#judah-and-the-lion:target .project-header-img {
    width: 100%;
    height: auto;
    background-size:cover;
    position: fixed;
    z-index: -1;
}

#judah-and-the-lion:target{
    width: 98vw;
    height: 100vw;
    opacity: 1;
    background: #FFF2EB;
    top:0;
    position: absolute;
    left: 0;
    pointer-events: all;
}

.judahContent{
    display: none;
    top:0;
    position: absolute;
}

#judah-and-the-lion:target .judahContent{
    display: block;
    top:0;
}

#judah-and-the-lion:target .project-header-logo {
    width: 75vw;
    height: auto;
    top: 7vw;
    margin-left: 12vw;
    margin-right: 12vw;
    background-size:cover;
    position: relative;
}

#judah-and-the-lion:target .project-header-desc-full{
    margin-top:10vw;
    display: block;
    background-color: white;
}

#judah-and-the-lion #promo-img-2:hover + #promo-desc-2{
    transition: .3s;
    margin-top: -43vw !important;
    margin-left: 24vw !important;
}

#judah-and-the-lion #promo-img-5:hover + #promo-desc-5{
    transition: .3s;
    margin-top: -43vw !important;
    margin-left: 24vw !important;
}


#judah.project .project-desc-back{
    background: rgba(255, 242, 235, 0.959) !important;
}
.project-wrapper #judah h1{
    color:#FF7065;
}

.project-wrapper #judah h2{
    color:rgba(94, 97, 102, 0.856);
}

.project-wrapper #judah p{
    color: rgba(255, 255, 255, 0.61);
    font-weight: 300;
    display: block;
}

#judah.project .project-sub{
    display: block;
    color:#5E6166!important;
}

#judah.project .project-desc-back{
    background: rgba(0, 0, 0, 0.877) !important;
}



/*--------------------utdcs--------------------*/

#computer-science{
    width: 28vw;
    height: 28vw;
    background: black;
    opacity:0;
    transition: .5s;
    position: absolute;
    top: 0px;
    left: 6vw;
    z-index: 2;
    pointer-events: none;
}

#computer-science:target .project-header-img {
    width: 100%;
    height: auto;
    background-size:cover;
    position: fixed;
    z-index: -1;
}

#computer-science:target{
    width: 98vw;
    height: 100vw;
    opacity: 1;
    background: #FFF2EB;
    top:0;
    position: absolute;
    left: 0;
    pointer-events: all;
}

.csContent{
    display: none;
    top:0;
    position: absolute;
}

#computer-science:target .csContent{
    display: block;
    top:0;
}

#computer-science:target .project-header-logo {
    width: 75vw;
    height: auto;
    top: 7vw;
    margin-left: 12vw;
    margin-right: 12vw;
    background-size:cover;
    position: relative;
}

#computer-science:target .project-header-desc-full{
    margin-top:10vw;
    display: block;
    background-color: #0B1C03;
}

.project-wrapper #cs h1{
    color:#FCB61A;
}

.project-wrapper #cs h2{
    color: rgb(46, 71, 13);
}

.project-wrapper #cs p{
    color: rgba(255, 255, 255, 0.61);
    font-weight: 300;
    display: block;
}

#cs.project .project-sub{
    display: block;
    color:rgb(46, 71, 13) !important;
}

#cs.project .project-desc-back{
    background: rgba(185, 99, 84, 0.952) !important;
}

/*--------------------cg--------------------*/

#collective-group{
    width: 28vw;
    height: 28vw;
    background: black;
    opacity:0;
    transition: .5s;
    position: absolute;
    top: 0px;
    left: 6vw;
    z-index: 2;
    pointer-events: none;
}

#collective-group:target .project-header-img {
    width: 100%;
    height: auto;
    background-size:cover;
    position: fixed;
    z-index: -1;
}

#collective-group:target{
    width: 98vw;
    height: 100vw;
    opacity: 1;
    background: #FFF2EB;
    top:0;
    position: absolute;
    left: 0;
    pointer-events: all;
}

.cgContent{
    display: none;
    top:0;
    position: absolute;
}

#collective-group:target .cgContent{
    display: block;
    top:0;
}

#collective-group:target .project-header-logo {
    width: 75vw;
    height: auto;
    top: 7vw;
    margin-left: 12vw;
    margin-right: 12vw;
    background-size:cover;
    position: relative;
}

#collective-group:target .project-header-desc-full{
    margin-top:10vw;
    display: block;
    background-color: rgb(30, 30, 53);
}

.project-wrapper #cg h1{
    color:#ffef95;
}

.project-wrapper #cg h2{
    color:rgb(125, 181, 255);
}

.project-wrapper #cg p{
    color: rgba(255, 255, 255, 0.61);
    font-weight: 300;
    display: block;
}

#cg .project-sub{
    display: block;
    color:rgb(40, 54, 63) !important;
}

#cg .project-desc-back{
    background: rgba(77, 132, 139, 0.966) !important;
}

/*--------------------gallery--------------------*/



.project-wrapper #gallery-p h1{
    color:#ffe4c1;
}

.project-wrapper #gallery-p h2{
    color:rgb(73, 131, 207);
}

.project-wrapper #gallery-p p{
    color: rgba(255, 255, 255, 0.61);
    font-weight: 300;
    display: block;
}

#gallery-p .project-sub{
    display: block;
    color:rgb(30, 43, 51) !important;
}

#gallery-p .project-desc-back{
    background: rgba(85, 145, 136, 0.911) !important;
}
